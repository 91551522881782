<template>
    <div class="page home">
        <div class="srhBox flex_l_c">
            <div class="mainTit">资源检索</div>
            <div class="flex1 flex_l_c">
                <input type="text" class="mainBdColor srhIpt" placeholder="请输入搜索关键词" v-model="srhStr" maxlength="100">
                <button class="srhBtn mainBdColor mainBgColor" @click="search"><i class="el-icon-search"></i>搜索</button>
            </div>
        </div>
        <div class="plateBox exhibitionBox" v-show="exhibitionList.length > 0">
            <div class="flex_b_t">
                <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>展览一览</span></div>
                <div class="seeMore pointer mainColor" @click="seeMore">查看更多 ></div>
            </div>
            <div>
                <div class="exhibitionItem pointer" v-for="(item, index) in exhibitionList" :key="index"
                     @click="view(item)">
                    <div class="h100">
                        <div>
                            <div class="titleBox">
                                <el-tooltip effect="dark" :content="item.exhibitionName" placement="top">
                                    <div class="title omit">
                                        <span class="numberBox mainColor" v-show="item.exhibitionId">
                                            <span class="numberBg mainBgColor mainBgTColor"></span>
                                            <span>{{item.exhibitionId}}</span>
                                        </span>
                                        <span>{{item.exhibitionName}}</span>
                                    </div>
                                </el-tooltip>
                                <!-- <div class="privacy">{{item.efieldName10}}</div> -->
                            </div>
                            <!--<div class="tagBox">
                                <el-tooltip effect="dark" :content="item.efieldName13" placement="top" :disabled="!item.efieldName13">
                                    <div :class="['tagItem mainColor omit', item.efieldName13 ? 'mainBdColor':'']">
                                        <span>{{item.efieldName13}}</span>
                                    </div>
                                </el-tooltip>
                            </div>-->
                            <div class="infoBox">
                                <div class="item omit" v-if="item.efieldName15">
                                    <span class="field">主办方：</span>
                                    <span>{{item.efieldName15}}</span>
                                </div>
                                <div class="item omit" v-if="item.exhibitionUserName">
                                    <span class="field">策展人：</span>
                                    <span>{{item.exhibitionUserName}}</span>
                                </div>
                                <div class="item omit" v-if="item.exhibitionTypeValue">
                                    <span class="field">展览类型：</span>
                                    <span :prop="item.exhibitionTypeValue"
                                          v-for="item1 in $dictUtils.getDictList('exhibition_type')"
                                          :key="item1.value"
                                          :label="item1.label"
                                          :value="item1.value">
                                        <span>{{item1.value==item.exhibitionTypeValue?item1.label:""}}</span>
                                    </span>
                                </div>
                                <div class="item omit" v-if="item.beginDatetime">
                                    <span class="field">展览时间：</span>
                                    <el-tooltip effect="dark" :content="`${item.beginDatetime}～${item.endDatetime}`"
                                                placement="top">
                                        <span>{{item.beginDatetime}}～{{item.endDatetime}}</span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="flex_b_c bottomInfo">
                            <div class="flieBox mainColor">
                                <span class="iconfont icon-wendang"></span>{{item.exhibitionTotal ? item.exhibitionTotal
                                : 0}}
                            </div>
                            <div class="text_right">
                                <el-button type="primary" plain v-show="item1.linkUrl" v-for="(item1, index1) in item.sysExhibitionLinks" :key="index1" size="small" @click.stop="webpage(item1)">{{item1.linkName}}</el-button>

                                <!--<div :class="['btnItem', index1%2 == 0 ? 'mainBgColor' : '', item1.linkUrl? 'mainBgColor': 'disBtnItem']"
                                     v-for="(item1, index1) in item.sysExhibitionLinks" :key="index1"
                                     @click.stop="item1.linkUrl && webpage(item1)">
                                    {{item1.linkName}}
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="plateBox">
            <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>统计分析</span></div>
            <div class="flex_b_c">
                <div class="statisticItem flex_b_c">
                    <div>
                        <div class="num">{{informationCount}}</div>
                        <div class="txt">入库展览总数</div>
                    </div>
                    <div class="numberBox iconBox mainColor">
                        <span class="numberBg mainBgColor mainBgTColor"></span>
                        <span class="iconfont icon-zongshu"></span>
                    </div>
                </div>
                <div class="statisticItem flex_b_c">
                    <div>
                        <div class="num">{{fileCollectCount}}</div>
                        <div class="txt">入库档案总数</div>
                    </div>
                    <div class="numberBox iconBox mainColor">
                        <span class="numberBg mainBgColor mainBgTColor"></span>
                        <span class="iconfont icon-wendang"></span>
                    </div>
                </div>
                <div class="statisticItem flex_b_c">
                    <div>
                        <div class="num">{{culturalCount}}</div>
                        <div class="txt">入库文物总数</div>
                    </div>
                    <div class="numberBox iconBox mainColor">
                        <span class="numberBg mainBgColor mainBgTColor"></span>
                        <span class="iconfont icon-wenwu"></span>
                    </div>
                </div>
                <div class="statisticItem flex_b_c">
                    <div>
                        <div class="num">{{dynamicTags.length}}</div>
                        <div class="txt">标签总数</div>
                    </div>
                    <div class="numberBox iconBox mainColor flex_c_c">
                        <span class="numberBg mainBgColor mainBgTColor"></span>
                        <span class="iconfont icon-biaoqian"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="plateBox flex_b_t">
            <div class="chartItem">
                <div class="flex_b_t">
                    <div class="plateTit flex_l_c"><span
                            class="plateTitIcon mainBgColor"></span><span>入库展览总量年度折线图</span></div>
                    <div class="unit">{{informationCount}}（单位：个）</div>
                </div>
                <div class="chartBox">
                    <div ref="chart1" style="width: 100%; height: 21.25rem"></div>
                </div>
            </div>
            <div class="chartItem">
                <div class="flex_b_t">
                    <div class="plateTit flex_l_c"><span
                            class="plateTitIcon mainBgColor"></span><span>入库档案总量年度折线图</span></div>
                    <div class="unit">{{fileCollectCount}}（单位：个）</div>
                </div>
                <div class="chartBox">
                    <div ref="chart2" style="width: 100%; height: 21.25rem"></div>
                </div>
            </div>
        </div>
        <div class="plateBox" v-show="dynamicTags.length > 0">
            <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>标签词云</span></div>
            <div>
                <div @click="biaoqian(item)" class="tag pointer" v-if="item.fileTotal>0"
                     v-for="(item, index) in dynamicTags" :key="index">
                    {{item.name}}({{item.fileTotal}})
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                srhStr: "",
                exhibitionList: [],
                gridOption: {
                    top: 40,
                    left: 20,
                    right: 30,
                    bottom: 22,
                    containLabel: true,
                },
                xAxisOption: {
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        color: "#000000",
                        fontSize: 14,
                        // fontFamily: "monospace",
                        fontWeight: "bold",
                        padding: [15, 0, 0, 0]
                    },
                    axisLine: {
                        show: false,
                    },
                    data: [],
                },
                yAxisOption: {
                    type: 'value',
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#ababab",
                        fontSize: 14,
                        // fontFamily: "monospace",
                        fontWeight: "bold",
                    },
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#F0F0F0',
                        }
                    },
                },
                seriesOption: [
                    {
                        type: 'line',
                        data: [],
                        symbol: 'circle',
                        symbolSize: 12,
                        lineStyle: {
                            width: 1.5,
                        },
                        itemStyle: {
                            borderColor: "#ffffff",
                            borderWidth: 3,
                            shadowBlur: 1,
                            shadowColor: "rgba(168,33,38,0.4400)"
                        },
                    }
                ],
                //标签
                dynamicTags: [],
                //文物清单总数
                culturalCount: '',
                //文件归档总数
                fileCollectCount: '',
                //展览有归档文件总数
                informationCount: '',
                yearData1: [],
                numData1: [],
                yearData2: [],
                numData2: [],

            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme() {
                this.changeMainColor(this.defaultTheme);
                this.queryTotalExhibition();
                this.fileCollectQueryCount();
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
            this.refreshList();
            this.queryTotalExhibition();
            this.labelIndexQueryAll();
            this.culturalQueryCount();
            this.fileCollectQueryCount();
            this.informationQueryCount();

        },
        methods: {
            //文件标签
            labelIndexQueryAll() {
                this.dynamicTags = []
                let that = this;
                this.$axios(this.api.common.labelIndexQueryAll2, {}, 'get').then(data => {
                    if (data.status && data.data) {
                        data.data.forEach(function (v) {
                            that.dynamicTags.unshift(v)
                        })
                    }
                })
            },
            //文物清单总数
            culturalQueryCount() {
                this.$axios(this.api.common.culturalRelicsQueryCount, {}, 'get').then(data => {
                    this.culturalCount = data.data
                })
            },
            //文件归档总数
            fileCollectQueryCount() {
                this.$axios(this.api.common.fileCollectQueryCount, {}, 'get').then(data => {
                    let list = [];
                    var yearList = [];
                    var totalList = []
                    for (var index = 0; index < 6; index++) {
                        var chart = {}
                        chart.year = new Date().getFullYear() - index;
                        chart.total = 0
                        list.unshift(chart);
                    }
                    var total = 0;
                    // console.log("========",data)
                    data.data.forEach(function (v) {
                        total += parseInt(v.fieldName30)
                        if (list[0].year >= v.pyear) {
                            list[0].total += parseInt(v.fieldName30)
                        }
                        for (var i = 1; i < list.length; i++) {
                            if (list[i].year == v.pyear) {
                                list[i].total = v.fieldName30
                            }
                        }
                        // that.yearData2.push(v.pyear + '')
                        // that.numData2.push(parseInt(v.fieldName30))
                    })
                    for (var j = 0; j < list.length; j++) {
                        yearList.push(list[j].year)
                        if (j == 0) {
                            totalList.push(parseInt(list[j].total))
                            continue;
                        }
                        list[j].total = parseInt(list[j].total) + parseInt(list[j - 1].total)
                        totalList.push(parseInt(list[j].total))
                        Object.assign([], list);
                    }
                    this.chart2({
                        yearData: yearList,
                        numData: totalList,
                    })
                    this.fileCollectCount = total
                })
            },
            //展览归档总数
            informationQueryCount() {
                this.$axios(this.api.infor.informationQueryCount, {}, 'get').then(data => {
                    this.informationCount = data
                })
            },
            //展览归档总数
            queryTotalExhibition() {
                this.$axios(this.api.infor.informationQueryTotalExhibition, {}, 'get').then(data => {
                    let list = [];
                    let yearList = [];
                    let totalList = []
                    for (let index = 0; index < 6; index++) {
                        let chart = {}
                        chart.year = new Date().getFullYear() - index;
                        chart.total = 0
                        list.unshift(chart);
                    }

                    data.forEach(function (v) {

                        if (list[0].year >= v.time && v.total > 0) {
                            list[0].total += 1
                        }
                        for (let i = 1; i < list.length; i++) {
                            if (list[i].year == v.time && v.total > 0) {
                                list[i].total += 1
                            }
                        }
                        // var obj = v.time
                        // var total = v.total
                        // var newObj = JSON.parse(JSON.stringify(obj))
                        // var newObj1 = JSON.parse(JSON.stringify(total))
                        // that.yearData1.push(newObj)
                        // that.numData1.push(newObj1)
                    });
                    for (let j = 0; j < list.length; j++) {
                        yearList.push(list[j].year)
                        if (j == 0) {
                            totalList.push(parseInt(list[j].total))
                            continue;
                        }
                        list[j].total = parseInt(list[j].total) + parseInt(list[j - 1].total)
                        totalList.push(parseInt(list[j].total))
                        Object.assign([], list);
                    }
                    this.chart1({
                        yearData: yearList,
                        numData: totalList,
                    })

                })
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.infor.informationByCardHome, {
                    'exhibitionName': '',
                    'efieldName20': 0,
                    'pageNo': 1,
                    'pageSize': 9,
                }, 'post').then(data => {
                    console.log(data);
                    if (data && data.status) {
                        this.exhibitionList = data.data
                        this.$nextTick(() => {
                            this.changeMainColor(this.defaultTheme);
                        })
                    }
                })
            },
            // 搜索
            search() {
                let item = {name: '', id: ''}
                sessionStorage.setItem('homeSrh', this.srhStr)
                this.$router.push({path: '/zlzs/retrieval/retrievedResult', query: {val: item}});
            },
            // 查看更多
            seeMore() {
                let item = {name: '', id: ''}
                this.$router.push({path: '/zlzs/retrieval/retrievedResult', query: {val: item}});
            },
            // 展览网页
            webpage(row) {
                window.open(row.linkUrl);
            },
            // 数字展厅
            hall(row) {

            },
            //标签点击
            biaoqian(item) {
                this.showSrh = false;
                this.tabIndex = "2"
                this.$nextTick(() => {
                    // this.$refs.itemRetrieval.initBiaoqian(item, this.srhStr);
                    this.$router.push({path: '/zlzs/retrieval/retrievedResult', query: {val: item}});
                })
            },
            // 查看展览
            view(row) {
                this.$router.push({path: '/zlzs/retrieval/exhibitionDetail', query: {id: row.id}});
            },
            // 入库展览总量年度折线图
            chart1(data) {
                let chart1 = this.$echarts.init(this.$refs.chart1);
                let xAxisOption = this.xAxisOption;
                xAxisOption.data = data.yearData;
                let seriesOption = this.seriesOption;
                seriesOption[0].data = data.numData;
                seriesOption[0].lineStyle.color = this.defaultTheme;
                seriesOption[0].itemStyle.color = this.defaultTheme;
                let option = {
                    tooltip: {},
                    xAxis: xAxisOption,
                    grid: this.gridOption,
                    yAxis: this.yAxisOption,
                    series: seriesOption
                }
                chart1.setOption(option);
                // 图表自适应
                window.addEventListener("resize", function () {
                    chart1.resize();
                });
            },
            // 入库档案总量年度折线图
            chart2(data) {
                let chart2 = this.$echarts.init(this.$refs.chart2);
                let xAxisOption = this.xAxisOption;
                xAxisOption.data = data.yearData;
                let seriesOption = this.seriesOption;
                seriesOption[0].data = data.numData;
                seriesOption[0].lineStyle.color = this.defaultTheme;
                seriesOption[0].itemStyle.color = this.defaultTheme;
                let option = {
                    tooltip: {},
                    xAxis: xAxisOption,
                    grid: this.gridOption,
                    yAxis: this.yAxisOption,
                    series: seriesOption
                }
                chart2.setOption(option);
                // 图表自适应
                window.addEventListener("resize", function () {
                    chart2.resize();
                });
            },
        },
    }
</script>

<style>

</style>
